import './css/react.scss';

import React from 'react';
import {createRoot} from 'react-dom/client';
import "regenerator-runtime/runtime";
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-toastify/dist/ReactToastify.min.css'
import App from './App';
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import rootReducer from './reducers'
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import {persistReducer, persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import {AuthProvider} from "./context/auth-context";
import {RefererProvider} from "./context/referer-context";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {createInstance, MatomoProvider} from '@datapunt/matomo-tracker-react'
import { registerSW } from "virtual:pwa-register";
import { pwaInfo } from "virtual:pwa-info";
import { experimental_createPersister } from "@tanstack/query-persist-client-core";
import { get, set, del, createStore as createIDBStore} from "idb-keyval";

function newIdbStorage(idbStore) {
    return {
        getItem: async (key) => await get(key, idbStore),
        setItem: async (key, value) => await set(key, value, idbStore),
        removeItem: async (key) => await del(key, idbStore),
    };
}

console.log(pwaInfo);

// add this to prompt for a refresh
const updateSW = registerSW({
    //immediate: true,
    onNeedRefresh() {
        if (confirm("New content available. Reload?")) {
            updateSW(true);
        }
    },
});
//import reportWebVitals from './reportWebVitals';

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const persister = createSyncStoragePersister({
//     storage: window.localStorage,
// });

function createIDBPersister(idbValidKey = import.meta.env.VITE_API_URL) {
    return {
        persistClient: async (client) => {
            await set(idbValidKey, client);
        },
        restoreClient: async () => {
            return await get(idbValidKey);
        },
        removeClient: async () => {
            await del(idbValidKey);
        },
    };
}
const persister = createIDBPersister();

let store;
if (import.meta.env.VITE_DEBUG !== undefined && import.meta.env.VITE_DEBUG) {
    store = createStore(
        persistedReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
} else {
    store = createStore(
        persistedReducer
    );
}

const persistor = persistStore(store);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 30, // 30 seconds
            persister: experimental_createPersister({
                storage: newIdbStorage(createIDBStore(import.meta.env.VITE_API_URL, import.meta.env.VITE_API_URL)),
                maxAge: 1000 * 60 * 60 * 24 * 365, // 1 year
            }),
            retry: false,
            //retry: import.meta.env.MODE === 'production',
            refetchOnWindowFocus: import.meta.env.MODE === 'production',
            staleTime: import.meta.env.VITE_REACT_QUERY_STALE_TIME*1,
            //staleTime: 5000,
            cacheTime: import.meta.env.VITE_REACT_QUERY_CACHE_TIME*1,
            //cacheTime: 5000,
            keepPreviousData: true,
            networkMode: 'offlineFirst',
        }
    }
});

const matomoInstance = createInstance({
    urlBase: import.meta.env.VITE_MATOMO_URL,
    siteId: import.meta.env.VITE_MATOMO_ID,
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 15 // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: { // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: false,
        setRequestMethod: 'POST'
    }
});



const root = createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <I18nextProvider i18n={i18n}>
                <AuthProvider>
                    <RefererProvider>
                        <QueryClientProvider
                            client={queryClient}
                            // persistOptions={{ persister }}
                            // onSuccess={() => {
                            //     // resume mutations after initial restore from localStorage was successful
                            //     queryClient.resumePausedMutations().then(() => {
                            //         queryClient.invalidateQueries()
                            //     })
                            // }}
                        >

                            <MatomoProvider value={matomoInstance}>
                                <App />
                            </MatomoProvider>
                            {/*<ReactQueryDevtools initialIsOpen={false} />*/}
                        </QueryClientProvider>
                    </RefererProvider>
                </AuthProvider>
            </I18nextProvider>
        </PersistGate>
    </Provider>
);

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname === 'pwa.omegaroc.com'
);

if ("serviceWorker" in navigator && !isLocalhost) {
    const updateSW = registerSW({
        immediate: true,
        //onNeedRefresh() {
            //toast.info('An update is available!');
            // Toastify({
            //     text: `<h4 style='display: inline'>An update is available!</h4>
            //    <br><br>
            //    <a class='do-sw-update'>Click to update and reload</a>  `,
            //     escapeMarkup: false,
            //     gravity: "bottom",
            //     onClick() {
            //         updateSW(true);
            //     }
            // });
       // }
    });
    // && !/localhost/.test(window.location)) {
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();