const API_URL = import.meta.env.VITE_API_URL;
const API_URL_I18N = import.meta.env.VITE_API_URL + '/{lng}';

const ApiLanguages = [
    'fr', 'en'
];

const ApiUrls = {
    API_URL: API_URL,
    API_URL_I18N: API_URL_I18N,
    GUIDEBOOKS: API_URL_I18N + '/search-guidebook',
    GUIDEBOOK: API_URL_I18N + '/guidebook/{slug}',
    GUIDEBOOK_VALIDATION: API_URL_I18N + '/guidebook/validation/{slug}',
    GUIDEBOOK_DETAIL: API_URL_I18N + '/guard/guidebook',
    ACTIVATION: API_URL_I18N + '/activation',
    VALIDATION: API_URL_I18N + '/validation',
    REGION: API_URL_I18N + '/region',
    CLIFF: API_URL_I18N + '/cliff',
    CLIFF_UNLOCK: API_URL_I18N + '/guard/cliff',
    SECTOR_ROUTE: API_URL_I18N + '/sector_route',
    SECTOR_ROUTE_UNLOCK: API_URL_I18N + '/guard/sector_route',
    MULTIPITCH_ROUTE: API_URL_I18N + '/multipitch_route',
    MULTIPITCH_ROUTE_UNLOCK: API_URL_I18N + '/guard/multipitch_route',
    MULTIPITCH_AUTOCOMPLETE: API_URL_I18N + '/guard/autocomplete/multipitch',
    SECTOR_AUTOCOMPLETE: API_URL_I18N + '/guard/autocomplete/sector',
    SEARCH: API_URL_I18N + '/search',
    SEARCH_MY_ROUTE: API_URL_I18N + '/guard/search/myroute',
    LOGIN: API_URL_I18N + '/customer/login',
    RESET_PASSWORD: API_URL_I18N + '/customer/password_reset_request',
    RESET_PASSWORD_CONFIRM: API_URL_I18N + '/customer/password_reset',
    RESET_PASSWORD_CHANGE: API_URL_I18N + '/customer/password_reset_change',
    REGISTER: API_URL_I18N + '/customer/register',
    REGISTER_CONFIRM: API_URL_I18N + '/customer/confirm',
    TICKING: API_URL_I18N + '/guard/ticking',
    TICKINGS: API_URL_I18N + '/guard/tickings',
    PRESENTATION: API_URL_I18N + '/presentation/{slug}',
    TICKING_CREATE: API_URL_I18N + '/guard/ticking/create',
    TICKING_UPDATE: API_URL_I18N + '/guard/ticking/update/{id}',
    CUSTOMER: API_URL_I18N + '/guard/customer/{email}',
    ADDRESS: API_URL_I18N + '/guard/address',
    ADDRESS_DETAILS: API_URL_I18N + '/guard/address/{id}',
    ADDRESS_DEFAULT_SET: API_URL_I18N + '/guard/address/{id}/default',
    ADDRESS_DEFAULT_GET: API_URL_I18N + '/guard/address/default',
    ADDRESS_EDIT: API_URL_I18N + '/guard/address/{id}',
    STORE_ROUTE_UPDATE: API_URL_I18N + '/guard/customer/store_route',
    STORE_ROUTE_CLEAR: API_URL_I18N + '/guard/customer/clear_store_route',
    STORE_CLIFF_UPDATE: API_URL_I18N + '/guard/customer/store_cliff',
    STORE_CLIFF_CLEAR: API_URL_I18N + '/guard/customer/clear_store_cliff',
    ERROR: API_URL + '/error/log',
    SHOP_CART_POST: API_URL_I18N + '/guard/cart/',
    REFRESH_TOKEN: API_URL + '/token/refresh',
    SHOP_CART_GET: API_URL_I18N + '/guard/cart',
    SHOP_ADD_TO_CART: API_URL_I18N + '/guard/cart/add-to-cart/{id}',
    REFERER: API_URL_I18N + '/guard/customer/referer',
    SHOP_REMOVE_FROM_CART: API_URL_I18N + '/guard/cart/remove-from-cart/{id}',
    PASSWORD_UPDATE: API_URL_I18N + '/guard/customer/password-change',
    USER_INFORMATION_UPDATE: API_URL_I18N + '/guard/customer/information-change',
    ORDER_CURRENT: API_URL_I18N + '/guard/order/current',
    ORDER_BILLING: API_URL_I18N + '/guard/order/billing',
    ORDER_PAYMENT: API_URL_I18N + '/payment/{id}',
    ORDER_FINALIZE_ZERO: API_URL_I18N + '/guard/order/finalize-zero-order',
    ORDERS_GET: API_URL_I18N + '/guard/order',
    ORDERS_GET_SINGLE: API_URL_I18N + '/guard/order/{id}',
    CART_RESET: API_URL_I18N + '/guard/cart/reset',
    BOOKMARK_UPDATE: API_URL_I18N + '/guard/customer/bookmark',
};

const PWAUrlsParams = {
    routes: {
        en: 'routes',
        fr: 'voies'
    },
    routeTypeCliff: {
        en: 'crag',
        fr: 'falaise'
    },
    routeTypeMultipitch: {
        en: 'multi-pitch',
        fr: 'grande-voie'
    },
    approach: {
        en: 'approach',
        fr: 'approche',
    },
    details: {
        en: 'details',
        fr: 'details',
    },
    search: {
        en: 'search',
        fr: 'recherche'
    },
    account: {
        en: 'account',
        fr: 'compte'
    },
    myaccount: {
        en: 'my-account',
        fr: 'mon-compte'
    },
    ticking: {
        en: 'logbook',
        fr: 'carnet'
    },
    guidebook: {
        en: 'guidebook',
        fr: 'topo',
    },
    guidebooks: {
        en: 'guidebooks',
        fr: 'topos',
    },
    cart: {
        en: 'cart',
        fr: 'panier'
    },
    presentation: {
        en: 'presentation',
        fr: 'presentation'
    },
    resetpassword: {
        en: 'reset-password',
        fr: 'reinit-mdp'
    },
    register: {
        en: 'sign-up',
        fr: 'inscription'
    },
    login: {
        en: 'sign-in',
        fr: 'connexion'
    },
    profile: {
        en: 'profile',
        fr: 'profil'
    },
    orders: {
        en: 'orders',
        fr: 'commandes'
    },
    address: {
        en: 'addresses',
        fr: 'adresses'
    },
    information: {
        en: 'information',
        fr: 'information'
    },
    checkout: {
        en: 'checkout',
        fr: 'tunnel'
    },
    billing: {
        en: 'billing',
        fr: 'facturation'
    },
    payment: {
        en: 'payment',
        fr: 'paiement'
    },
    complete: {
        en: 'complete',
        fr: 'resultat'
    },
    success: {
        en: 'success',
        fr: 'succes'
    },
    cancelled: {
        en: 'cancelled',
        fr: 'annule'
    },
    crudCreate: {
        en: 'new',
        fr: 'nouveau'
    },
    crudEdit: {
        en: 'edit',
        fr: 'modifier'
    },
    crudView: {
        en: 'view',
        fr: 'voir'
    }
};

const PWAUrls = {
    HOME: '/{lng}/{routes}',
    SEARCH: '/{lng}/{search}',
    MY_ROUTES: '/{lng}/{routes}',
    TOPO_LIST: '/{lng}/{guidebooks}',
    TOPO: '/{lng}/{guidebook}/{slug}',
    TOPO_VALIDATION: '/{lng}/{guidebook}/{slug}/validation',
    TOPO_ACTIVATION: '/{lng}/{guidebook}/{slug}/activation',
    CLIFF: '/{lng}/{routes}/{routeTypeCliff}/{slugRegion}/{slug}',
    SECTOR_ROUTE: '/{lng}/{routes}/{routeTypeCliff}/{slugRegion}/{slugGroupRoute}/{slug}',
    SECTOR_ROUTE_DETAILS: '/{lng}/{routes}/{routeTypeCliff}/{slugRegion}/{slugGroupRoute}/{slug}/{details}',
    SECTOR_ROUTE_APPROACH: '/{lng}/{routes}/{routeTypeCliff}/{slugRegion}/{slugGroupRoute}/{slug}/{approach}',
    MULTIPITCH_ROUTE: '/{lng}/{routes}/{routeTypeMultipitch}/{slugRegion}/{slug}',
    TICKING_LIST: '/{lng}/{ticking}',
    TICKING_CREATE: '/{lng}/{ticking}/{crudCreate}',
    TICKING_EDIT: '/{lng}/{ticking}/{crudEdit}/{id}',
    TICKING_VIEW: '/{lng}/{ticking}/{crudView}/{id}',
    USER_CHECKOUT_PAYMENT: '/{lng}/{myaccount}/{checkout}/{payment}',
    USER_CHECKOUT_BILLING: '/{lng}/{myaccount}/{checkout}/{billing}',
    USER_CHECKOUT_SUCCESS: '/{lng}/{myaccount}/{checkout}/{complete}/{success}',
    USER_CHECKOUT_CANCELLED: '/{lng}/{myaccount}/{checkout}/{complete}/{cancelled}',
    USER_CART: '/{lng}/{myaccount}/{cart}',
    USER_INFORMATION: '/{lng}/{myaccount}/{information}',
    USER_ADDRESS_CREATE: '/{lng}/{myaccount}/{address}/{crudCreate}',
    USER_ADDRESS_EDIT: '/{lng}/{myaccount}/{address}/{id}/{crudEdit}',
    USER_ADDRESS_LIST: '/{lng}/{myaccount}/{address}',
    USER_ORDER_LIST: '/{lng}/{myaccount}/{orders}',
    USER_ORDER: '/{lng}/{myaccount}/{orders}/{id}',
    USER_PROFILE: '/{lng}/{myaccount}/{profile}',
    USER_LOGIN: '/{lng}/{account}/{login}',
    USER_REGISTER: '/{lng}/{account}/{register}',
    USER_RESET_PASSWORD: '/{lng}/{account}/{resetpassword}',
    PRESENTATION: '/{lng}/{presentation}/{slug}',
}
const getUrl = (url, params = {}) => {

    url = replaceUrlParams(url, params.lng);
    for (const [key, value] of Object.entries(params)) {
        url = url.replace('{' + key + '}', value);
    }

    return encodeURI(url);
}

const replaceUrlParams = (url, lng) => {
    //const baseUrl = url;
    const regexp = /(?:\{)(.*?)(?=\})/gm;
    const matches = url.matchAll(regexp);

    for (const arrMatch of matches) {
        const match = arrMatch[1];
        if (PWAUrlsParams[match]) {
            if (PWAUrlsParams[match][lng]) {
                url = url.replace('{' + match + '}', PWAUrlsParams[match][lng]);
            } else {
                //console.log('Missing params in ' + match + '[@lng=' + lng + '] urls.js. Url: ' + baseUrl);
            }
        } else {
            //console.log('Missing params in ' + match + ' urls.js. Url: ' + baseUrl);
        }
    }

    return url;
}

export {
    PWAUrls,
    PWAUrlsParams,
    replaceUrlParams,
    ApiUrls,
    ApiLanguages,
    getUrl,
} ;
